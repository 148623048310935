@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Instrument+Serif&family=Roboto+Slab&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@1,600&display=swap');


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.loader:after {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #888;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

p {
  line-height: 1.7;
}

h1,
h2,
h3 {
  font-weight: 600;
  margin-bottom: 8px;
  color: #000;
}

.container {
  width: 100%;
  background-color: #ffffff;
  max-width: 100%;
  text-align: center;
}

.heading {
  display: flex;
  justify-content: center;
  text-align: left;
  flex-direction: column;
  font-size: 2rem;
  margin-bottom: 35px;
}

.heading2 {
  display: flex;
  justify-content: center;
  text-align: left;
  flex-direction: column;
  font-size: 2rem;
  margin-bottom: 35px;
}

.formLogin {
  display: block;
  justify-content: center;
  align-items: center;
  width: 60%;
}

.formRegister {
  display: block;
  justify-content: center;
  align-items: center;
  width: 60%;
  height: 100%;
}

.forgot-password {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  color: rgb(64, 63, 63);
  margin-bottom: 2.5rem;
  margin-left: auto;
  margin-right: auto;
  width: 45%;
}

.registerPage {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  color: rgb(64, 63, 63);
  margin-bottom: 2.5rem;
  margin-left: 0.5rem;
}

.reset-password {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  color: rgb(64, 63, 63);
  margin-bottom: 2.5rem;
  margin-left: auto;
  margin-right: auto;
  width: 45%;
}

.login {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  color: rgb(64, 63, 63);
  margin-bottom: 2.5rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.goals {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.goal {
  background-color: #f4f4f4;
  margin: 10px 0;
  padding: 20px 0 10px;
  position: relative;
}

.goal .close {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
  border: none;
  background: none;
}

.content {
  width: 70%;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 10px;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 15px;
  border: 2px solid #f07000;
  border-radius: 11.5px;
  margin-bottom: 2px;
  font-family: inherit;
  background-color: #fff6f5;
}

.form-group2 {
  margin-bottom: 10px;
}

.form-group2 input,
.form-group2 textarea,
.form-group2 select {
  width: 100%;
  padding: 14px;
  border: 2px solid #f07000;
  border-radius: 11.5px;
  font-family: inherit;
  background-color: #fff6f5;
}

.btn {
  margin-left: auto;
  margin-right: auto;
  height: 3.3rem;
  width: 8rem;
  border: none;
  border-radius: 30px;
  background: #ff6900;
  color: #fff;
  font-size: 17px;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

.btn-block {
  margin-bottom: 20px;
}

.btn:hover {
  transform: scale(0.98);
}

.btn2 {
  margin-left: auto;
  margin-right: auto;
  height: 3rem;
  width: 7rem;
  border: none;
  border-radius: 30px;
  background: #ff6900;
  color: #fff;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.btn-block2 {
  margin-bottom: 2px;
}

.btn2:hover {
  transform: scale(0.98);
}

.containerPrincipal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  color: white;
}

.sectionRight {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 85%;
  height: 100%;
  background-color: #fcf6ee;
}

.sectionLeft {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 55vw;
  height: 100vh;
}

.leftContain {
  width: 55vw;
  height: 100vh;
}

.orderLogo {
  position: absolute;
  width: 19.5%;
  height: 22%;
}

/* Icono-login */
.imgLogoLogin {
  display: flex;
  margin-left: 4.1vh;
  font-weight: none;
  margin-bottom: 3rem;
}

/* Icono-registro */
.imgLogoRegister {
  display: flex;
  margin-left: 4.1vh;
  font-weight: none;
  margin-bottom: 2rem;
}

.containerIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  /* bottom: 10rem; */
}

.navBarLogin {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10%;
  margin-bottom: 4rem;
  margin-right: 14rem;
}

.navBarRegister {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10%;
  margin-bottom: 4rem;
  margin-right: 14rem;
}

ul {
  list-style: none;
}

li {
  line-height: 2.2;
  display: inline;
  padding: 2rem;
}

.anclaOne {
  text-decoration: none;
  padding: 15px 38px;
  border-radius: 25px;
}

.anclaTwo {
  text-decoration: none;
  padding: 15px 30px;
  border-radius: 25px;
  border: 1px solid #f07000;
}

.txtInfoLogin {
  font-size: 1rem;
  width: 45%;
  color: #000000;
}

.txtInfoRegister {
  font-size: 1rem;
  width: 50%;
  color: #000000;
}

.register {
  display: flex;
  margin-top: 1.5rem;
  justify-content: center;
  color: #5f5c5c;
}

.info-register {
  justify-content: center;
  color: black;
  margin-left: 0.5rem;
  color: #ea4335;
  font-weight: bold;
  text-decoration: none;
}

.forgot-password-mobile {
  display: none;
}

.reset-password-mobile {
  display: none;
  color: #FF5C00;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
}

/* .containerButtone {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  position: relative;
} */

.closeButton:hover {
  transform: translate(50%, -35%);
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -35%);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  padding: 0;
  background-color: #ff0000;
  border: none;
  color: #ffffff;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}


/* Laptop */
@media only screen and (max-width : 1366px) {
  .emptyProducts {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 1.2rem;
    color: rgb(0, 0, 0);
    ;

    /* text-align: center; */
  }

  h1,
  h2,
  h3 {
    margin-bottom: 8px;
    font-size: 25px;
  }

  .heading {
    display: flex;
    justify-content: center;
    text-align: left;
    flex-direction: column;
    font-size: 2rem;
  }

  .heading2 {
    display: flex;
    justify-content: center;
    text-align: left;
    flex-direction: column;
    font-size: 2rem;
    margin-bottom: 5%;
  }

  .formLogin {
    display: block;
    justify-content: center;
    align-items: center;
    width: 65%;
  }

  .formRegister {
    display: block;
    justify-content: center;
    align-items: center;
    width: 60%;
    height: 100%;
    margin-bottom: 3rem;
  }

  .form-group2 {
    margin-bottom: 10px;
  }

  .form-group2 input {
    width: 100%;
    padding: 8px;
    border: 2px solid #f07000;
    border-radius: 11.5px;
    font-family: inherit;
    background-color: #fff6f5;
  }

  .forgot-password {
    width: 100%;
    margin-top: 1.5rem;
    color: rgb(64, 63, 63);
    font-size: 14px;
  }

  .reset-password {
    display: flex;
    justify-content: center;
    margin-top: -1rem;
    color: rgb(64, 63, 63);
    font-size: 14px;
  }

  .forgot-password-mobile {
    display: none;
  }

  .btn {
    margin-left: auto;
    margin-right: auto;
    height: 2.5rem;
    width: 8rem;
    border: none;
    border-radius: 30px;
    background: #ff6900;
    color: #fff;
    font-size: 17px;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  }

  .btn2 {
    border: none;
    border-radius: 30px;
    background: #ff6900;
    color: #fff;
    font-size: 17px;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  }

  .sectionRight {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 85%;
    height: 100%;
    background-color: #fcf6ee;
  }

  .sectionLeft {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 55vw;
    height: 100vh;
  }

  .leftContain {
    width: 55vw;
    height: 100vh;
  }

  .orderLogo {
    position: absolute;
    width: 20%;
    height: 22%;
  }

  .imgLogoLogin {
    display: flex;
    height: 45%;
    width: 45%;
    margin-left: 4.1vh;
    font-weight: none;
    margin-bottom: 1.5rem;
  }

  .imgLogoRegister {
    display: flex;
    margin-left: 4.1vh;
    font-weight: none;
    margin-bottom: 0.5rem;
    margin-top: 2rem;
    width: 40%;
    height: 50%;
  }

  .containerIcon {
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .navBarLogin {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10%;
    margin-bottom: 2rem;
    margin-top: 2rem;
    margin-right: 0rem;
  }

  .navBarRegister {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10%;
    margin-bottom: 2rem;
    margin-right: 0rem;
  }

  .reset-password-mobile {
    display: none;
    color: #FF5C00;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
  }

  .anclaOne {
    text-decoration: none;
    padding: 18px 38px;
    border-radius: 25px;
  }

  .anclaTwo {
    text-decoration: none;
    padding: 18px 30px;
    border-radius: 25px;
    border: 1px solid #f07000;
    margin-right: 4rem;
  }

  .txtInfoLogin {
    font-size: 14px;
    width: 70%;
    color: #000000;
  }

  .txtInfoRegister {
    font-size: 0.9rem;
    width: 100%;
    color: #000000;

  }

  .login {
    margin-top: 0;
    margin-bottom: 0;
  }

  .register {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #5f5c5c;
    font-size: 14px;
  }

  .info-register {
    justify-content: center;
    color: black;
    margin-left: 0.5rem;
    color: #ea4335;
    font-weight: bold;
    text-decoration: none;
  }

  .form-group input,
  .form-group textarea,
  .form-group select {
    width: 100%;
    padding: 10px;
    border: 2px solid #f07000;
    border-radius: 11.5px;
    margin-bottom: 2px;
    font-family: inherit;
    background-color: #fff6f5;
  }


}


/* Tablet */
@media only screen and (min-width: 600px) and (max-width:768px) {


  .imgLogoLogin {
    display: none;
    margin-left: 4.1vh;
    font-weight: none;
  }

  .formLogin {
    display: block;
    justify-content: center;
    align-items: center;
    width: 80%;
  }

  .formRegister {
    display: block;
    justify-content: center;
    align-items: center;
    width: 80%;
  }

  .heading h1 {
    font-size: 2rem;
    text-align: left;
    margin-top: 1.5rem;
  }

  .heading p {
    font-size: 1rem;
    width: 80%;
    text-align: left;
    color: #555;
  }

  .forgot-password {
    display: flex;
    justify-content: center;
    margin-top: 5%;
  }

  .reset-password {
    display: flex;
    justify-content: center;
  }

  .btn {
    font-size: 17px;
  }

  .sectionLeft {
    display: none;
  }

  .imgLogoLogin {
    display: flex;
    margin-top: 2rem;
    width: 30%;
  }

  .imgLogoRegister {
    display: flex;
    margin-top: 2rem;
    width: 30%
  }

  .btn-block {
    width: 100%;
    border-radius: 10px;
  }

  .navBarLogin {
    margin-top: 3rem;
    margin-bottom: 1rem;
    margin-right: 0rem;
  }

  .navBarRegister {
    margin-top: 3rem;
    margin-bottom: 1rem;
    margin-right: 0rem;
  }

  .sectionRight {
    background-color: white;
  }

  .forgot-password-mobile {
    display: none;
  }

  .register {
    display: flex;
    text-align: center;
    justify-content: center;
    color: #5f5c5c;
    margin-top: 5%;
  }

  .reset-password-mobile {
    display: none;
    color: #FF5C00;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
  }
}


/* Mobile */
@media only screen and (max-width : 600px) {
  .login {
    width: 100%;
    font-size: 15px;
  }

  .imgLogoLogin {
    display: none;
    margin-left: 4.1vh;
    font-weight: none;
  }

  .imgLogoRegister {
    display: none;
    margin-left: 4.1vh;
    font-weight: none;
  }

  .formLogin {
    display: block;
    justify-content: center;
    align-items: center;
    width: 80%;
  }

  .formRegister {
    display: block;
    justify-content: center;
    align-items: center;
    width: 80%;
  }

  .heading h1 {
    font-size: 2rem;
    text-align: left;
    margin-top: 1.5rem;
  }

  .heading p {
    font-size: 1rem;
    width: 80%;
    text-align: left;
    color: #555;
  }

  .txtInfoRegister {
    width: 100%;
  }

  .forgot-password {
    display: flex;
    justify-content: left;
  }

  .reset-password {
    display: none;
    justify-content: left;
  }

  .reset-password-mobile {
    display: flex;
    justify-content: center;
    color: #FF5C00;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
  }

  .btn {
    font-size: 16px;
  }

  .sectionLeft {
    display: none;
  }

  .imgLogoLogin {
    display: flex;
    margin-top: 2rem;
    width: 65%;
  }

  .imgLogoRegister {
    display: flex;
    margin-top: 2rem;
    width: 65%
  }

  .btn-block {
    width: 100%;
    border-radius: 10px;
  }

  .navBarLogin {
    margin-top: 3rem;
    margin-bottom: 1rem;
    margin-right: 0rem;
  }

  .navBarRegister {
    margin-top: 3rem;
    margin-bottom: 1rem;
    margin-right: 0rem;
  }

  .sectionRight {
    background-color: white;
  }

  .forgot-password {
    display: none;
  }

  .forgot-password-mobile {
    display: flex;
    justify-content: center;
    font-size: 14px;
    text-decoration: none;
    color: #FF5C00;
    font-weight: bold;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .register {
    display: block;
    text-align: center;
    justify-content: center;
    color: #5f5c5c;
  }

  .btn2 {
    width: 100%;
    border-radius: 12px;
    font-size: 15px;
  }

}


/* HomePage */
.mainHome {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100vw;
  height: 100vh;
  background-color: #a39d9d;
}

.containerText {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  flex-direction: column;
  width: 50%;
  height: 100%;
  background-color: #FFEFD8;
}

.containImage {
  display: flex;
  justify-content: space-evenly;
  width: 50%;
  height: 100%;
  align-items: center;
  background-color: #FFEFD8;
}

.imgPlate {
  width: 65%;
  height: 65%;
}

.information {
  margin: 4rem;
  width: 70%;
  flex-wrap: wrap;
  font-size: 20px;
}

.titleInfo {
  width: 70%;
  font-size: 65px;
  flex-wrap: wrap;
}

.btnExplore {
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  width: 18%;
  background-color: #FF5C00;
  padding: 1.2rem;
  border-radius: 5rem;
  font-size: 18px;
  color: white;
  font-weight: 200;
  border: none;
  cursor: pointer;
  margin-right: auto;
  margin-left: 8.5rem;
  font-weight: bold;
}

.btnExplore:hover {
  background-color: #bf8914;
}

/* Laptop */
@media only screen and (max-width : 1366px) {
  .mainHome {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100vw;
    height: 100vh;
    background-color: #a39d9d;
  }


  .containerText {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    flex-direction: column;
    width: 50%;
    height: 100%;
    background-color: #FFEFD8;
  }

  .containImage {
    display: flex;
    justify-content: space-evenly;
    width: 50%;
    height: 100%;
    align-items: center;
    background-color: #FFEFD8;
  }

  .imgPlate {
    width: 60%;
    height: 55%;
  }

  .information {
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    width: 70%;
    flex-wrap: wrap;
    font-size: 18px;
  }

  .titleInfo {
    width: 70%;
    font-size: 55px;
    flex-wrap: wrap;
  }

  .btnExplore {
    cursor: pointer;
    margin-right: auto;
    margin-left: 6.2rem;
    font-weight: bold;
  }
}


/* Tablet */
@media only screen and (max-width: 768px) {

  .mainHome {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    flex-wrap: wrap;
    width: 100vw;
    height: 100vh;
    background-color: #FFEFD8;
    overflow-y: scroll;
  }

  .containerText {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    height: 50%;
  }

  .containImage {
    display: flex;
    width: 100vw;
    height: 50%;
    align-items: center;
    background-color: #FFEFD8;
  }

  .imgPlate {
    width: 50%;
    height: 50%;
  }

  .information {
    margin: 1rem;
    width: 75%;
    font-size: 28px;
    text-align: center;
    flex-wrap: wrap;
  }

  .titleInfo {
    width: 85%;
    text-align: center;
    flex-wrap: wrap;
    font-size: 50px;
    margin-top: 5rem;
  }

  .btnExplore {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    width: 25%;
    font-size: 22px;
    font-weight: bold;
    margin-left: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .btnExplore:hover {
    background-color: #bf8914;
  }
}






/* Mobile HomePage */
@media only screen and (max-width: 600px) {

  .mainHome {
    display: flex;
    flex-direction: column;
    text-align: center;
    flex-wrap: wrap;
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
  }

  .containerText {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 50%;
  }

  .containImage {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50%;
    background-color: #FFEFD8;
  }

  .imgPlate {
    width: 80%;
    height: 40%;
  }

  .information {
    margin: 0.5rem;
    width: 90%;
    font-size: 20px;
    text-align: center;
    flex-wrap: wrap;
  }

  .titleInfo {
    width: 75%;
    margin-top: 3rem;
    margin-bottom: 1rem;
    text-align: center;
    flex-wrap: wrap;
    font-size: 32px;
  }

  .btnExplore {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    width: 40%;
    font-size: 18px;
    font-weight: bold;
    margin-left: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .btnExplore:hover {
    background-color: #bf8914;
  }

  .imgPlateAnchor {
    width: 30px;
    height: 30px;
    margin-right: 25px;
  }
}


/* Page-Forgot-password */

.mainForgotPassword {
  width: 100vw;
  height: 100vh;
  background: rgb(0, 0, 0);
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  height: 100%;
  background: white;

  /* margin-top: 5rem; */
}

.content {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content .imgLogo {
  width: 165px;
  height: 128px;
}

.img {
  display: flex;
  justify-content: center;
  margin-bottom: 4rem;
}

.heading {
  margin-bottom: 1rem;
}

.campoDatos {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  /* align-items: center; */
}

.campoDatos a {
  display: flex;
  justify-content: center;

}

.campoDatos .email {
  display: flex;
  flex-direction: column;
}

.email input {
  padding: 13px;
  border: 2px solid #cf6406;
  border-radius: 8px;
  font-family: inherit;
  background-color: #fff6f5;
  /* outline: rgb(38, 0, 255); */
}

.email input:focus {
  outline-color: rgb(255, 85, 0);
}

.btnSend {
  padding: 10px 250px;
  border: none;
  border-radius: 8px;
  background: #ff6900;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  margin-top: 3rem;
}

.btnSendForgotPass {
  padding: 10px;
  width: 100%;
  border: none;
  border-radius: 8px;
  background: #ff6900;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  margin-top: 2rem;
}

.btnSendResetPass {
  padding: 10px;
  width: 100%;
  border: none;
  border-radius: 8px;
  background: #ff6900;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  margin-top: 2rem;
}

.back {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  color: rgb(64, 63, 63);
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  font-size: 14px;
}

.back:hover {
  transform: scale(1);
}

/* SEGUNDO DIV */
.messageContent {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 100%;
  background: wheat;
}

.tittleForgotPass {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.textForgotPass {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.info {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 3.5rem;
}

.info p {
  width: 60%;
  text-align: center;
  font-weight: bold;
  font-size: 1.6rem;
}

.imgMsg img {
  width: 400px;
  height: 400px;
}


/* Page-Update-Profile */

.mainUpdateProfile {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
  background-color: #FCEDE3;
}

.navBarInSideBar {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

/* uno */
.sideBar {
  display: block;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 6.2%;
  background-color: #FF5C00;
}

.listLinks {
  display: block;
}

/* logo.sideBar */
.imgIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85px;
  margin-left: 0.5rem;
  height: 70px;
}

.links {
  color: #ffffff;
  text-decoration: none;
  font-size: 1rem;
}

.linkSettings {
  text-decoration: none;
  font-size: 2rem;
}

/* dos */
.contentUpdateProfile {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: flex-start;
  width: 95.8vw;
  padding-top: 3.5rem;
  height: 100vh;
}

.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 25%;
  background: url(./Images/banner.png);
  background-size: cover;
  border: 1.5px solid #FF5C00;
}

.imgUpdatedProfile {
  width: 220px;
  height: 220px;
  position: absolute;
  top: 5.5rem;
  border: 5px solid #FF5C00;
  border-radius: 50%;
  border-style: dashed;
}

.inputUpload {
  width: 3rem;
  height: 2.5rem;
  position: absolute;
  top: 11.5rem;
  font-size: 2rem;
  border: none;
  cursor: pointer;
  border-radius: 25%;
}

.inputUpload::after {
  content: "+";
  position: absolute;
  width: 3rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 6px;
  background: #FF5C00;
  text-align: center;

}

.swal2-title {
  color: #000 !important;
}

.swal2-input:focus {
  outline: none !important;
}

.swal2-input {
  border: 2px solid #FF5C00 !important;
  border-radius: 8px !important;
}

.containerSwal {
  border-radius: 25px !important;
  border: 5px solid #FF5C00 !important;
}

.swal2-cancel {
  background-color: red !important;
}

.btnConfirmSwal {
  background-color: #FF5C00 !important;
}

.contentLoader {
  width: 10rem;
  height: 5rem;
}




.loaderUpdate {
  width: 3rem;
  height: 2.5rem;
  position: absolute;
  top: 5.5rem;
  right: 3.5rem;
  font-size: 2rem;
  border: none;
  cursor: pointer;
  border-radius: 25%;
}

.loaderUpdate {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite
}

.loaderUpdate::before,
.loaderUpdate::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #000000;
  animation: prixClipFix 2s linear infinite;
}

.loaderUpdate::after {
  inset: 8px;
  transform: rotate3d(90, 90, 0, 180deg);
  border-color: #FF5C00;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(360deg)
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)
  }

  75%,
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)
  }
}




.inputUpload::-webkit-file-upload-button {
  visibility: hidden;
}

/* Logos del .sideBar */
.iconHome {
  width: 30px;
  height: 25px;
}

.iconNotification {
  width: 23px;
  height: 25px;
}

.iconCalendary {
  width: 23px;
  height: 24px;
}

.iconStats {
  width: 30px;
  height: 20px;
}

.iconProfile {
  width: 24.5px;
  height: 23px;
}

/* Info Update-profile */
.groupText {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 21.2rem;
}

.nameUpdatedProfile {
  color: #000;
  font-weight: bold;
}

.emailUpdatedProfile {
  color: #FF5C00;
  font-weight: bold;
}

/* ------------ */

.formProfile {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 85%;
  height: 46%;
  position: absolute;
  top: 29rem;
  flex-wrap: wrap;
}

.inputs {
  width: 80%;
  height: 90%;
  border: none;
  outline: none;
  background: transparent;
  font-size: 18px;
}


.btnUpdate {
  cursor: pointer;
  width: 18rem;
  border-radius: 11.5px;
  border: none;
  background-color: #FF5C00;
  color: white;
  font-size: 1.1rem;
  font-weight: bold;
  height: 3rem;
  margin-top: 2rem;
}

.iconLogout {
  width: 50px;
  height: 45px;
  margin: 0;
  background-color: white;
  border-radius: 50%;
  padding: 10px 12px;
}

.containInputs {
  display: flex;
  width: 42%;
  height: 15%;
  justify-content: flex-start;
  align-items: center;
  border: 2px solid #f07000;
  border-radius: 11.5px;
  background-color: #fff6f5;
}

.imgUser {
  width: 7%;
  height: 55%;
  margin-right: 1rem;
  margin-left: 1rem;
  border-right: 1px solid #5d5b5bc8;
  padding-right: 0.5rem;
}

/* Laptop - Update-Profile */
@media only screen and (min-width: 1024px) and (max-width: 1366px) {
  .mainUpdateProfile {
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 100vh;
  }

  .navBarInSideBar {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  /* uno */
  .sideBar {
    display: block;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 6.2%;
    background-color: #FF5C00;
  }

  .listLinks {
    display: block;
  }

  /* logo.sideBar */
  .imgIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    margin-left: 0.5rem;
    height: 55px;
  }

  .links {
    color: #ffffff;
    text-decoration: none;
    font-size: 1rem;
  }

  .linkSettings {
    text-decoration: none;
    font-size: 2rem;
  }

  /* dos */
  .contentUpdateProfile {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: flex-start;
    width: 95.8vw;
    padding-top: 2.5rem;
    height: 100vh;
  }

  .banner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 25.5%;
    background: url(./Images/banner.png);
    background-size: cover;
    border: 1.5px solid #FF5C00;
  }

  .imgUpdatedProfile {
    width: 150px;
    height: 145px;
    position: absolute;
    top: 3.8rem;
    border: 5px solid #FF5C00;
    border-radius: 50%;
    border-style: dashed;
  }

  .btnUpload {
    width: 2.5rem;
    height: 2rem;
    position: absolute;
    top: 11.8rem;
    border-radius: 25%;
    background-color: #FF5C00;
    font-size: 2rem;
    border: none;
    cursor: pointer;
  }

  .btnUpdate {
    cursor: pointer;
    width: 15rem;
    border-radius: 11.5px;
    border: none;
    background-color: #FF5C00;
    color: white;
    font-size: 1.1rem;
    font-weight: bold;
    height: 3rem;
    margin-top: 2rem;
  }

  /* Info Update-Profile */
  .groupText {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 14.8rem;
  }

  .formProfile {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 88%;
    height: 40%;
    position: absolute;
    top: 20rem;
    flex-wrap: wrap;
  }

  .containInputs {
    display: flex;
    width: 39%;
    height: 16%;
    justify-content: flex-start;
    align-items: center;
    border: 2px solid #f07000;
    border-radius: 11.5px;
    background-color: #fff6f5;
    margin: 0.8rem;
  }


  /* Logos del .sideBar */

  .iconNotification {
    width: 18px;
    height: 18px;
  }

  .iconCalendary {
    width: 22px;
    height: 20px;
  }

  .iconStats {
    width: 20px;
    height: 15px;
  }

  .iconProfile {
    width: 20px;
    height: 20px;
  }

  .iconQR {
    width: 28px;
    height: 23px;
  }


  .listLinks {
    padding-top: 17px;
    padding-bottom: 15px;
    padding-right: 0px;
    padding-left: 0px;
    /* border: 2px solid green; */
  }

}

/* Page - QR */

.mainQR {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #FCEDE3;
}

.containerGenerateQR {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  /* border: 2px solid  black; */
}

.contentOfCards {
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: column;
}

.containerCardQR {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 22%;
  height: 100%;
  bottom: 25%;
  /* position: fixed; */
  font-family: Arial, sans-serif;
  padding: 20px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
  border: 3px solid #FF5C00;
  border-radius: 25px;
}

.containerQRS {
  position: relative;
  top: 1rem;
  /* flex-direction: row; */
  flex-wrap: wrap;
  width: 100%;
  /* height: 100%; */
  /* top: 20rem; */
  overflow: auto;
  max-height: 200px;
}

.titleQrsPreview {
  position: relative;
  top: 1rem;
}

.tittleCard {
  margin: 0 0 10px 0;
  font-size: 24px;
}

.imgReference {
  display: block;
  justify-content: center;
  align-items: center;
  width: 35%;
  height: 33%;
}


.formQR {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.labelFormQR {
  margin-bottom: 10px;
  font-size: 16px;
}

.inputQuantityQRs {
  padding: 10px;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  margin-bottom: 10px;
  width: 100%;
  font-size: 16px;
}

.btnDownloadQR {
  background-color: #4caf50;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}








/* Page - Profile */
.mainProfile {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #FCEDE3;
}



/* dos */
.contentProfile {
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  width: 100vw;
  height: 100vh;
}

.infoPersonal {
  padding: 1rem;
}

.containerProfile {
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: column;
  height: 80%;
  border-radius: 20px;
  width: 80%;
  border: 1.5px solid rgb(153, 149, 149);
}

.contentUserBanner {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
  width: 100%;
  margin-top: 1rem;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.bannerProfile {
  display: flex;
  background: url(./Images/banner.png);
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  width: 100%;

  height: 35%;
  border-bottom: 1.5px solid rgb(153, 149, 149);
}


.containerImgProfile {
  position: fixed;
  inset: 8rem 22rem 0rem 22rem;
  width: 9%;
  height: 18%;
  border: 5px solid #FF5C00;
  border-radius: 50%;
  border-style: dashed;
}

.imgProfile {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.nameProfile {
  top: 0.5rem;
  position: relative;
  bottom: 2rem;
  font-weight: bold;
  color: #FF5C00;
  font-size: 2rem;
}

.roleProfile {
  position: relative;
  top: -0.5rem;
  font-weight: bold;
  color: #010101;
  font-size: 2rem;
}

.roleProfileUpdate {
  position: relative;
  right: 30rem;
  font-weight: bold;
  color: #010101;
  font-size: 2rem;

}

/* .nameProfile, .nameUpdatedProfile::first-letter{
  text-transform: uppercase;
} */

/* contenedor de las 2 secciones inferiores */
.containersInfomation {
  display: flex;
  width: 100%;
  height: 65%;
}

/* seccion-izquierda */
.contactInformation {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-left: 5%;
  text-align: left;
  flex-direction: column;
  width: 50%;
  font-size: 18px;
  gap: 3px;
  border-right: 1px solid rgb(153, 149, 149);
}

.updateMessage-red {
  color: red;
  font-weight: 600;
}


.emailProfile,
.addressProfile,
.dniProfile,
.phoneProfile {
  font-weight: normal;
}

.dniProfile {
  font-weight: bold;
  color: #FF5C00;
}

.emailProfile {
  font-weight: bold;
  color: #FF5C00;
}

.type {
  font-weight: bold;
}

/* seccion-derecha */
.sectionToProfile {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;
  gap: 30px;
}

.infoToUpdate {
  font-size: 18px;
}

.anchorToUpdateProfile {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FF5C00;
  text-decoration: none;
  color: #ffffff;
  border-radius: 25px;
  width: 30%;
  height: 10%;
}


/* ResetPassword - Page */
.mainResetPassword {
  width: 100vw;
  height: 100vh;
  background: rgb(0, 0, 0);
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  height: 100%;
  background: white;

  /* margin-top: 5rem; */
}

.content {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content .imgLogo {
  width: 165px;
  height: 128px;
}

.img {
  display: flex;
  justify-content: center;
  margin-bottom: 4rem;
}

.heading {
  margin-bottom: 1rem;
}

.campoDatos {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  /* align-items: center; */
}

.campoDatos a {
  display: flex;
  justify-content: center;

}

.campoDatos .email {
  display: flex;
  flex-direction: column;
}

.email input {
  padding: 13px;
  border: 2px solid #cf6406;
  border-radius: 8px;
  font-family: inherit;
  background-color: #fff6f5;
  margin-bottom: 1rem;
  /* outline: rgb(38, 0, 255); */
}

.email input:focus {
  outline-color: rgb(255, 85, 0);
}

.inputConfirm {
  margin-top: 1rem;
}

.btnSend {
  padding: 10px 250px;
  border: none;
  border-radius: 8px;
  background: #ff6900;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  margin-top: 3rem;
}


/* SEGUNDO DIV */

.messageContent {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 100%;
  background: wheat;
}

.tittleForgotPass {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.textForgotPass {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.info {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 3.5rem;
}

.info p {
  width: 60%;
  text-align: center;
  font-weight: bold;
  font-size: 1.6rem;
}

.imgMsg img {
  width: 400px;
  height: 400px;
}


/* Page - Products */
.mainProducts {
  display: flex;
  height: 100vh;
  width: 100vw;
  background-color: #FCEDE3;
}

.containerProducts {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 95.8vw;
  padding-top: 3.5rem;
  height: 100vh;
}

.tittleMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FF5C00;
  padding: 0.1rem 3rem;
  border-radius: 30px;
}

.tittle {
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 2rem;
  font-family: 'Instrument Serif', serif;
  font-family: 'Roboto Slab', serif;
  font-weight: 200;
}

.tittleCategories {
  font-size: 2rem;
  font-family: 'Instrument Serif', serif;
  font-family: 'Roboto Slab', serif;
  font-weight: 200;
  margin-top: 2rem;
}

.containerCategories {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.containerCategoriesRol {
  display: flex;
  margin-top: 1rem;
  flex-wrap: wrap;
}

.containerLinks {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  position: relative;
  margin-left: 2rem;
  margin-right: 2rem;
  background-color: #ffffff;
  border: 1px solid #FF5C00;
  padding: 0.5rem 1.5rem;
  border-radius: 25px;
  cursor: pointer;
}

.categories {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #FF5C00;
  font-size: 18px;
}

.active {
  margin-left: 2rem;
  margin-right: 2rem;
  background-color: #FF5C00;
  border: 1px solid #FF5C00;
  padding: 0.5rem 1.5rem;
  border-radius: 25px;
}

.activeLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #ffffff;
  font-size: 18px;
}

.containerCards {
  display: flex;
  overflow: auto;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 3%;
  margin-bottom: 3%;
  width: 85%;
  height: 100%;
  /* border: 3px solid red; */
}

/* ocultar barra de scroll */
.containerCards::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}

.cardOrder:hover {
  border: 1px solid #FF5C00;
  color: black;
}

.cardOrder {
  display: flex;
  cursor: pointer;
  justify-content: center;
  flex-direction: column;
  margin-top: 5rem;
  margin-left: 2rem;
  margin-right: 2rem;
  position: relative;
  background-color: #FFFFFF;
  border-radius: 25px;
  width: 18%;
  height: 48%;
  margin-bottom: 3rem;
}


.infoOrder {
  margin-top: 3.5rem;
}

/* .infoOrder>p { */
/* overflow: hidden; */
/* Oculta cualquier texto que se salga del contenedor */
/* text-overflow: ellipsis; */
/* Agrega puntos suspensivos (...) al final del texto cuando se trunca */
/* font-size: 0.9rem; */
/* overflow-wrap: anywhere; */
/* } */

/* .infoOrder>h3 {
  overflow-wrap: anywhere;
  font-size: 0.9rem;
} */


.containerImgOrder {
  position: absolute;
  bottom: 11rem;
  width: 60%;
  height: 60%;
  left: 3.4rem;
  border-radius: 100%;
}

.containerImgOrderUser {
  position: absolute;
  bottom: 11.5rem;
  width: 60%;
  height: 65%;
  left: 3.8rem;
  /* border: 1px solid red; */
  border-radius: 100%;
}

.imgOrder {
  border: 0.5px solid #FF5C00;
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.imgOrderUser {
  border: 0.5px solid #FF5C00;
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}



.closeModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  padding: 1rem;
  left: 64%;
  top: 10%;
  width: 2.5%;
  height: 4%;
  border-radius: 50px;
  border: none;
  color: white;
  background: #ff0000;
  font-size: 1.5rem;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
}


.contentShoppingCart {
  padding: 20px;
}

/* Modal - CRUD */
.modalCreateProducts {
  display: none;
  top: 18%;
  background-color: rgb(255, 255, 255);
  position: absolute;
  width: 45%;
  height: 70%;
  z-index: 1;
  border-radius: 25px;
  border: 5px solid #FF5C00;
}

.modalCreateProducts.open {
  display: flex;
}

.btnCloseModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 72%;
  top: 20.5%;
  width: 2.5%;
  height: 4%;
  border-radius: 50px;
  border: none;
  background: #FF5C00;
  font-size: 1.5rem;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
}

.btnCloseModal:hover,
.btnCloseModal:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.7;
}

.formCreateProduct {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  width: 100%;
  height: 100%;
  margin-top: 2.5%;
  border-radius: 20px;
}

.inputProduct {
  display: flex;
  height: 10%;
  width: 75%;
  margin: 2%;
  margin-top: 1.5%;
  padding: 13px;
  border: 2px solid #cf6406;
  border-radius: 8px;
  font-family: inherit;
  background-color: #fff6f5;
}

.containerInputFile {
  position: relative;
  display: flex;
  justify-content: left;
  text-align: left;
  align-items: center;
  height: 10%;
  cursor: pointer;
  width: 75%;
  margin: 2%;
  margin-top: 1.5%;
  padding: 13px;
  border: 2px solid #cf6406;
  font-family: inherit;
  background-color: #fff6f5;
  -webkit-border-radius: 5px;
  -webkit-box-shadow: 0px 0px 0px #1a71a9;
  border-radius: 9px;
}

.btnUploadImage {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 5%;
  width: 100%;
  height: 100%;
  opacity: 0;
}


.inputProducts {
  display: flex;
  justify-content: left;
  text-align: left;
  flex-direction: column;
  margin: 2%;
  width: 75%;
}

.inputProductLabel {
  width: 100%;
  margin-bottom: 5px;
}

.inputProductSelect {
  display: flex;
  justify-content: left;

}

.custom-select {
  width: 100%;
  height: 10%;
}


.inputProductSelect .custom-select__control {
  height: 60px;
  font-family: inherit;
}

.inputProducts .react-select-container {
  width: 100%;
  /* Asegura que el contenedor del Select ocupe todo el ancho del contenedor */
}

.inputProductSelect .custom-select__control,
.inputProductSelect .custom-select__menu {
  border: 2px solid #cf6406;
  border-radius: 8px;
}

.inputProductSelect .custom-select__indicator-separator {
  display: none;
}

.inputProductSelect .custom-select__single-value {
  color: #333;
}

.inputProductSelect .custom-select__menu {
  margin-top: 0;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.inputProductSelect .custom-select__option {
  color: #333;
  padding: 8px 12px;
}

.inputProductSelect .custom-select__option--is-selected {
  background-color: #f0f0f0;
}

.inputProductSelect .custom-select__option--is-focused {
  background-color: #e0e0e0;
}



input:focus {
  outline: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.btnCreateProduct {
  cursor: pointer;
  border: none;
  width: 28%;
  height: 10%;
  font-size: 18px;
  border-radius: 25px;
  background-color: #FF5C00;
  color: #ffffff;
  font-weight: bold;
  margin-top: 2%;
  margin-bottom: 2%;
}

/* boton - card | crear producto */
.cardCreateProduct {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18%;
  height: 48%;
  margin-top: 5rem;
  margin-left: 2rem;
  margin-right: 2rem;
  background-color: #FFFFFF;
  border-radius: 25px;
  margin-bottom: 3rem;
}

.btnOpenModal {
  cursor: pointer;
  border: none;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  font-size: 4rem;
  background-color: #ffffff;
  color: #FF5C00;
}

.containerEdits {
  position: absolute;
  border-radius: 25px;
}

/* Boton eliminar producto */

.btnDeleteProduct {
  width: 2rem;
  height: 2rem;
  border-radius: 25px;
  margin-top: 1rem;
  margin-left: 2rem;
  position: static;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  border: none;
  font-size: 15px;
  border: 2px solid #FF5C00;
  cursor: pointer;
}

.btnDeleteProduct:hover,
.btnDeleteProduct:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.7;
}

.btnEditProduct {
  width: 2rem;
  height: 2rem;
  border-radius: 25px;
  margin-top: 1rem;
  margin-left: 1rem;
  position: static;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  border: none;
  font-size: 15px;
  border: 2px solid #FF5C00;
  cursor: pointer;
}

.btnEditProduct:hover,
.btnEditProduct:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.7;
}

.iconEdit {
  width: 55%;
  height: 55%;
  color: white;
}

.iconDelete {
  width: 65%;
  height: 60%;
  color: white;
}


/* Laptop - profile */
@media only screen and (min-width: 1024px) and (max-width: 1366px) {

  .containerImgProfile {
    position: fixed;
    inset: 8rem 2rem 0rem 16rem;
    width: 8%;
    height: 18%;
    border: 5px solid #FF5C00;
    border-radius: 50%;
    border-style: dashed;
  }

  .imgProfile {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}






/* Laptop - Products */
@media only screen and (min-width: 1024px) and (max-width: 1366px) {

  .mainProducts {
    display: flex;
    height: 100vh;
    width: 100vw;
    background-color: #FCEDE3;
  }

  .containerProducts {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 95.8vw;
    padding-top: 1.5rem;
    height: 100vh;
  }

  .tittleMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FF5C00;
    padding: 0.1rem 2rem;
    border-radius: 30px;
  }

  .tittle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    font-size: 25px;
    font-family: 'Instrument Serif', serif;
    font-family: 'Roboto Slab', serif;
    font-weight: 200;
  }

  .tittleCategories {
    font-size: 22px;
    font-family: 'Instrument Serif', serif;
    font-family: 'Roboto Slab', serif;
    font-weight: 200;
    margin-top: 1rem;
  }

  .containerCategories {
    margin-top: 4rem;
  }

  .containerCategoriesUser {
    display: flex;
    margin-top: 1.2rem;
    flex-wrap: wrap;
  }

  .containerLinks {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    position: relative;
    margin-left: 2rem;
    margin-right: 2rem;
    background-color: #ffffff;
    border: 1px solid #FF5C00;
    padding: 0.5rem 1.5rem;
    border-radius: 25px;
  }

  .categories {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #FF5C00;
    font-size: 18px;
  }

  .custom-select {
    /* Agrega estilos personalizados al componente Select */
    width: 100%;
  }


  .active:hover {
    transform: scale(1.1);
  }

  .active {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    position: relative;
    margin-left: 2rem;
    margin-right: 2rem;
    background-color: #FF5C00;
    border: 1px solid #FF5C00;
    padding: 0.5rem 1.5rem;
    border-radius: 25px;
  }

  .activeLink {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #ffffff;
    font-size: 18px;
  }

  .containerLinks:hover {
    transform: scale(1.1);
  }

  .containerCards {
    /* border: 2px solid red; */
    position: relative;
    overflow: auto;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 2%;
    margin-bottom: 3%;
    width: 85%;
    height: 65vh;
  }

  /* ocultar barra de scroll */
  .containerCards::-webkit-scrollbar {
    width: 0px;
    background-color: transparent;
  }

  .cardOrder:hover {
    border: 1px solid #FF5C00;
    color: black;
  }

  .cardOrder {
    display: flex;
    cursor: pointer;
    justify-content: center;
    flex-direction: column;
    position: relative;
    background-color: #FFFFFF;
    border-radius: 25px;
    width: 18%;
    height: 58%;
    margin-top: 4.5rem;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 3rem;
  }

  .nameOrder,
  .priceOrder,
  .descriptionOrder,
  .amountProduct,
  .categoryProduct {
    text-align: left;
    font-size: 12.4px;
    margin-right: 0.5rem;
    margin-left: 1.5rem;
    margin-top: -0.2rem;
  }

  .containerImgOrder {
    position: absolute;
    bottom: 10.8rem;
    width: 65%;
    height: 60%;
    left: 2.2rem;
    border-radius: 100%;
  }

  .containerEdits {
    margin-left: -0.5rem;
  }

  .cardOrderUser {
    display: flex;
    cursor: pointer;
    justify-content: center;
    flex-direction: column;
    position: relative;
    background-color: #FFFFFF;
    border-radius: 25px;
    width: 18%;
    height: 50%;
    margin-top: 4.5rem;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 3rem;
  }

  .containerImgOrderUser {
    position: absolute;
    bottom: 11.8rem;
    width: 65%;
    height: 65%;
    left: 2.3rem;
    border-radius: 100%;
  }

  .imgOrder {
    width: 95%;
    height: 95%;
    position: relative;
    top: 2rem;
    border-radius: 100%;
  }

  .imgOrderUser {
    width: 95%;
    height: 95%;
    position: relative;
    top: 2rem;
    border-radius: 100%;
  }

  .infoOrder {
    margin-top: 1rem;
  }

  /* Modal - CRUD */
  .modalCreateProducts {
    display: none;
    top: 18%;
    background-color: rgb(255, 255, 255);
    position: absolute;
    width: 45%;
    height: 70%;
    z-index: 1;
    border-radius: 25px;
    border: 5px solid #FF5C00;
  }

  .modalCreateProducts.open {
    display: flex;
  }

  .btnCloseModal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 72%;
    top: 20.5%;
    width: 2.5%;
    height: 4%;
    border-radius: 50px;
    border: none;
    background: #FF5C00;
    font-size: 1.5rem;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
  }

  .btnCloseModal:hover,
  .btnCloseModal:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.7;
  }

  .formCreateProduct {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    width: 100%;
    height: 100%;
    padding-bottom: 1.5em;
    margin-top: 2.5%;
    border-radius: 20px;
  }

  .inputProduct {
    display: flex;
    height: 10%;
    width: 75%;
    margin: 2%;
    margin-top: 1.5%;
    padding: 13px;
    border: 2px solid #cf6406;
    border-radius: 8px;
    font-family: inherit;
    background-color: #fff6f5;
  }

  input:focus {
    outline: none;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .btnCreateProduct {
    cursor: pointer;
    border: none;
    width: 35%;
    height: 15%;
    font-size: 17px;
    border-radius: 25px;
    background-color: #FF5C00;
    color: #ffffff;
    font-weight: bold;
    margin-top: 1%;
    margin-bottom: 2%;
  }

  /* boton - card | crear producto */
  .cardCreateProduct {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18%;
    height: 58%;
    margin-top: 4.5rem;
    margin-left: 2rem;
    margin-right: 2rem;
    background-color: #FFFFFF;
    border-radius: 25px;
    border: 2px solid #FF5C00;
    margin-bottom: 3rem;
  }

  .cardCreateProduct:hover {
    background-color: #ea681298;
    transform: scale(1.1);
  }

  .btnOpenModal {
    cursor: pointer;
    border: none;
    width: 100%;
    height: 100%;
    border-radius: 25px;
    font-size: 4rem;
    background-color: #ffffff;
    color: #FF5C00;
  }

  /* Boton eliminar producto */
  .btnDeleteProduct {
    width: 2rem;
    height: 2rem;
    border-radius: 25px;
    margin-left: 2rem;
    position: static;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    border: none;
    font-size: 15px;
    border: 2px solid #FF5C00;
    cursor: pointer;
  }

  .btnDeleteProduct:hover,
  .btnDeleteProduct:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.7;
  }

  .btnEditProduct {
    width: 2rem;
    height: 2rem;
    border-radius: 25px;
    margin-left: 1rem;
    position: static;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    border: none;
    font-size: 15px;
    border: 2px solid #FF5C00;
    cursor: pointer;
  }

  .btnEditProduct:hover,
  .btnEditProduct:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.7;
  }

  .iconEdit {
    width: 55%;
    height: 55%;
    color: white;
  }

  .iconDelete {
    width: 65%;
    height: 60%;
    color: white;
  }

  .inputProducts {
    display: flex;
    flex-direction: column;
    margin: 2%;
    width: 75%;
    /* border: 2px solid red; */
  }

  .inputProductSelect .custom-select__control {
    height: 35px;
    font-size: 14px;
  }

  .containerInputFile > p {
    font-size: 14px;
  }

}



/* Page-notifications */
.mainNotifications {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
  background-color: #FCEDE3;
}

.contentNotifications {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.containerNotifications {
  display: flex;
  justify-items: center;
  align-items: center;
  overflow-y: auto;
  flex-wrap: wrap;
  width: 85%;
  height: 85%;
  padding-top: 1%;
  padding-bottom: 1%;
  /* border: 3px solid rgb(0, 0, 0); */
  border-radius: 25px;
}

/* ocultar barra de scroll */
.containerNotifications::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}

.containerNotification {
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.5%;
  margin-bottom: 0.2%;
  background-color: #ffffff;
  border-radius: 25px;
  width: 98%;
  height: 15%;
}

.containerNotification:hover {
  border: 2px solid #000000;
}

.containerImageOrder {
  width: 6%;
  height: 75%;
}

.img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.infoO {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnSelectOrder {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 12px;
  border: 3px solid #FF5C00;
  cursor: pointer;
}

.iconSelected {
  width: 50%;
  height: 65%;
  cursor: pointer;
}

.containerButton {
  width: 4%;
  height: 40%;
  ;
  margin-left: 2%;
}

.nameNotification,
.priceNotification,
.descriptionNotification {
  font-size: 18px;
  margin-left: 5%;
  color: rgb(0, 0, 0);
  flex-wrap: wrap;
  /* border: 1px solid rgb(0, 0, 0); */
  border-radius: 25px;
  padding: 1%;
}

/* Page - Users */

.mainUsers {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
  background-color: #FCEDE3;
}

.contentUsers {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.containerCardsUsers {
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: row;
  overflow-y: auto;
  flex-wrap: wrap;
  width: 80%;
  height: 80%;
  /* border: 3px solid rgb(0, 0, 0); */
  border-radius: 25px;
}

/* ocultar barra de scroll */
.containerCardsUsers::-webkit-scrollbar {
  background-color: transparent;
}

/* cartas */

.cardUser {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 17em;
  height: 19em;
  margin: 10px;
  perspective: 1000px;
}

.cardFront,
.cardBack {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  backface-visibility: hidden;
  border: 3px solid #ffffff;
  border-radius: 25px;
  padding: 10px;
  box-sizing: border-box;
  transition: transform 0.5s;
}

.cardFront {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* cursor: pointer; */
  background-color: #f8f8f8;
  border: 3px solid #FF5C00;
}

.cardFrontContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.cardFrontImage {
  width: 9em;
  height: 8.5em;
  object-fit: scale-down;
  border-radius: 50%;
  border: 4px solid #FF5C00;
  border-style: dashed;
}

.cardFrontText {
  /* border: 2px solid green; */
  margin-top: 10%;
  margin-bottom: 10%;
  font-weight: bold;
}

.showDetailsButton {
  background-color: #FF5C00;
  color: #ffffff;
  padding: 10px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.cardBack {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: rotateY(180deg);
  background-color: #ffffff;
  border: 3px solid #FF5C00;
  font-size: 14.5px;
  font-weight: bold;
}

.flipped .cardFront {
  transform: rotateY(180deg);
}

.flipped .cardBack {
  transform: rotateY(0);
}

.flipToFrontButton {
  background-color: #dc3545;
  color: #fff;
  border: none;
  padding: 10px 12px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
}


/* contenedor de inputs-radio */
.containerOptions {
  display: flex;
  justify-content: left;
  text-align: left;
  align-items: last baseline;
  flex-direction: column;
  /* border: 1px solid black; */
  margin-top: 4%;
  margin-bottom: 2%;
}

.containerOptions>p {
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  align-items: center;
}



.radio-text {
  margin-left: 8px;
  /* Aplica un margen izquierdo para separar el texto del círculo de opción */
}

.btnAssignRole {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45%;
  height: 20%;
  background-color: #000000;
  color: white;
  margin-top: 5%;
  padding: 7%;
  border-radius: 25px;
  border: none;
}

.btnAssignRole:hover {
  background-color: #464343;
}


/* Page - Products-User */

.mainProductsUser {
  display: flex;
  width: 100vw;
  align-items: center;
  height: 100vh;
  justify-content: center;
  background-color: #FCEDE3;
}

.containerProductsUser {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.containerCategoriesUser {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin-top: 2rem;
  flex-wrap: wrap;
  /* border: 2px solid green; */
}



.tittleUser {
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 1.9rem;
  font-family: 'Instrument Serif', serif;
  font-family: 'Roboto Slab', serif;
  font-weight: 200;
}

.containerButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 35%;
  margin-top: 2%;
  padding-bottom: 5%;
  text-align: center;
  /* border: 2px solid green; */
}

.priceOrderUser {
  text-align: center;
  padding-left: 9.5%;
  color: #FF5C00;
  font-weight: bold;
}

.infoOrderUsers {
  margin-top: 6rem;
}

.closeModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  padding: 1rem;
  left: 64%;
  top: 10%;
  width: 2.5%;
  height: 4%;
  border-radius: 50px;
  border: none;
  color: white;
  background: #ff0000;
  font-size: 1.5rem;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
}

.btnAddToCar {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 2%;
  height: 2%;
  padding: 1rem;
  margin-right: 1.5rem;
  border: none;
  background-color: #FF5C00;
  border-radius: 25px;
  font-size: 15px;
  color: white;
  cursor: pointer;
}

.cardOrderUser {
  display: flex;
  cursor: pointer;
  justify-content: center;
  flex-direction: column;
  margin-top: 5rem;
  margin-left: 2rem;
  margin-right: 2rem;
  position: relative;
  background-color: #FFFFFF;
  border-radius: 25px;
  width: 18%;
  height: 42%;
  margin-bottom: 3rem;
}

.cardOrderUser:hover {
  border: 1px solid #FF5C00;
  color: black;
}


.tittleMenuUser {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FF5C00;
  padding: 0.1rem 3rem;
  border-radius: 30px;
  margin-right: 0;
}

.containerTittle {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 20%;
  margin: 0;
  margin-top: 2%;
  /* border: 2px solid black; */
}

.containerIconToCart {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15%;
  height: 3rem;
  background-color: #ffffff;
  border-radius: 7px;
  cursor: pointer;
}

.containerIconToCart:hover {
  border: 1px solid #FF5C00;
  color: black;
}

.iconToCar {
  width: 40%;
  height: 45%;
}

.nameOrder,
.priceOrder,
.descriptionOrder,
.amountProduct,
.categoryProduct {
  text-align: left;
  overflow-wrap: anywhere;
  font-size: 1rem;
  margin-left: 2rem;
  margin-top: -0.4rem;
}

.nameOrderUser {
  text-align: left;
  overflow-wrap: anywhere;
  font-size: 1rem;
  margin-left: 1.5rem;
  margin-top: -0.4rem;
}

.categoryOrderUser {
  text-align: left;
  overflow-wrap: anywhere;
  font-size: 1rem;
  margin-left: 1.5rem;
  margin-top: -0.4rem;
}

.nameOrderUserLabel {
  text-align: left;
  overflow-wrap: anywhere;
  font-size: 1rem;
  font-weight: bold;
  margin-right: 11rem;
  margin-top: -0.4rem;
  color: #FF5C00;
}

.categoryOrderUserLabel {
  text-align: left;
  overflow-wrap: anywhere;
  font-size: 1rem;
  font-weight: bold;
  margin-right: 10rem;
  margin-top: -0.4rem;
  color: #FF5C00;
}


/* laptop */
@media only screen and (min-width: 1024px) and (max-width: 1366px) {

  .cardOrderUser {
    display: flex;
    cursor: pointer;
    justify-content: center;
    flex-direction: column;
    margin-top: 5rem;
    margin-left: 2rem;
    margin-right: 2rem;
    position: relative;
    background-color: #FFFFFF;
    border-radius: 25px;
    width: 18%;
    height: 58%;
    margin-bottom: 3rem;
  }

  .cardOrderUser:hover {
    border: 1px solid #FF5C00;
    color: black;
  }

  .infoOrderUsers {
    margin-top: 3rem;
  }

  .nameOrder,
  .priceOrder,
  .descriptionOrder,
  .amountProduct,
  .categoryProduct {
    text-align: left;
    overflow-wrap: anywhere;
    font-size: 1rem;
    margin-left: 1.3rem;
    margin-top: -0.4rem;
  }

  .nameOrderUser {
    text-align: left;
    overflow-wrap: anywhere;
    font-size: 1rem;
    margin-left: 1.3rem;
    margin-top: -0.4rem;
  }

  .categoryOrderUser {
    text-align: left;
    overflow-wrap: anywhere;
    font-size: 1rem;
    margin-left: 1.5rem;
    margin-top: -0.4rem;
  }

  .nameOrderUserLabel {
    text-align: left;
    overflow-wrap: anywhere;
    font-size: 1rem;
    font-weight: bold;
    margin-right: 6rem;
    margin-top: -0.4rem;
    color: #FF5C00;
  }

  .categoryOrderUserLabel {
    text-align: left;
    overflow-wrap: anywhere;
    font-size: 1rem;
    font-weight: bold;
    margin-right: 5rem;
    margin-top: -0.4rem;
    color: #FF5C00;
  }

  .containerIconToCart {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18%;
    height: 3rem;
    background-color: #ffffff;
    border-radius: 7px;
    cursor: pointer;
  }

  .containerIconToCart:hover {
    border: 1px solid #FF5C00;
    color: black;
  }

  .iconToCar {
    width: 45%;
    height: 45%;
  }

  .btnOpenModal:hover {
    border: 1px solid #FF5C00;
  }

  button:hover {

    transform: scale(1.2);
  }

  .btnAddToCar:hover {
    transform: scale(1.2);
  }

  .btnAddToCar {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 1%;
    height: 1%;
    padding: 0.8rem;
    margin-right: 1.5rem;
    border: none;
    background-color: #FF5C00;
    border-radius: 25px;
    font-size: 13px;
    color: white;
    cursor: pointer;
  }

  .priceOrderUser {
    text-align: center;
    padding-left: 11%;
    color: #FF5C00;
    font-weight: bold;
  }

  .containerEdits {
    margin-left: -0.7rem;
  }

}


/* Page - Shopping-cart */

/* Parrafo - productsUser - vacios */
.cart-empty {
  position: fixed;
  top: 20rem;
}

.cart-emptyCart {
  display: flex;
  align-items: center;
}

.mainShoppingCart {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 40vw;
  height: 100vh;
}

.contentShoppingCart {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  height: 85vh;
  background: linear-gradient(0deg, rgba(255, 247, 247, 0.2), rgba(255, 247, 247, 0.2)), rgb(255, 255, 255);
  border-radius: 24px;
  border: 5px solid #FF5C00;
}


.containerProductsShoppingCart {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  overflow-y: auto;
  width: 95%;
  height: 50%;
  padding-top: 5%;
  padding-bottom: 5%;
  margin: 2%;
  gap: 25px;
  /* border: 3px solid rgb(0, 0, 0); */
}


/* ocultar barra de scroll */
.containerProductsShoppingCart::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}

/* contenedor de orden-carrito */
.containerProductCart {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  background-color: #f5f3f3;
  border-radius: 50px;
  width: 90%;
  height: 28%;
}


.containerImageProduct {
  width: 17%;
  height: 100%;
  border-radius: 50px;
  box-shadow: 8px 0px 12px rgba(0, 0, 0, 0.2);
}

.infoProduct {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  width: 25%;
  height: 80%;
  margin-left: -30%;
}

.priceProduct {
  font-size: 17px;
  width: 100%;
  margin-right: 50%;
  /* border: 2px solid red; */
}

/* contenedor de botones sumar y restar cantidad */
.containerSubAndAdd {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 35%;
}

/* botones */
.btnSubQuantity {
  cursor: pointer;
  width: 15%;
  height: 90%;
  border: none;
  border-radius: 25px;
  background-color: #FF5C00;
  color: #ffffff;
  font-size: 18px;
}

.btnAddQuantity {
  cursor: pointer;
  width: 16%;
  height: 90%;
  border: none;
  border-radius: 25px;
  background-color: #FF5C00;
  color: #ffffff;
  font-size: 18px;
}

/* contenedor de boton eliminar de carrito y precio */
.containerButtonCart {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 50px;
  width: 10%;
  height: 100%;
  margin-right: 5%;
}

.btnDeleteCart {
  width: 50%;
  height: 30%;
  background-color: transparent;
  border-radius: 12px;
  cursor: pointer;
  border: none;
  cursor: pointer;
}

.deleteToCar {
  width: 90%;
  height: 90%;
  margin-left: -15%;
}

/* Contenedor del titulo del carrito */
.containerTittleCart {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 10%;
  width: 90%;
  margin-bottom: 2%;
}

.line {
  width: 100%;
  margin-top: 1%;
  border: 1px solid black;
}

.titleShoppingCart {
  font-size: 50px;
  font-family: 'Roboto Slab', serif;
  margin-bottom: 2%;
}

/* Contenedor - seleccionar forma de pago */
.infoCart {
  display: flex;
  cursor: pointer;

  justify-content: center;
  text-align: center;
  align-items: center;
  border: 1px solid #FF5C00;
  width: 70%;
  height: 13%;
  background-color: #fde8d9;
  margin-top: 5%;
  border-radius: 50px;
  gap: 25px;
}

/* Contenedor de medio de pago */
.containerTextPay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  /* border: 1px solid blue; */
  word-break: break-all;
  flex-wrap: wrap;
}

.containerTextPayOrder {
  display: flex;
  /* margin-right: 1rem; */
  justify-content: center;
  align-items: center;
  width: 100%;
  word-wrap: break-word;
  height: 100%;
  /* border: 1px solid blue; */
  /* word-break: break-all; */
  flex-wrap: wrap;
}

.customSelect {
  text-align: center;
  width: 100%;
  height: 45%;
  font-size: 14px;
  border: 1px solid #000000;
  border-radius: 4px;
  background-color: transparent;
  color: #000000;
  outline: none;
}

/* Contenedor de imgBank - pay */
.containerImgPay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 100%;
  /* border: 1px solid green; */
}

.imgPay {
  width: 100%;
  height: 100%;
}

.containerLine {
  height: 2%;
  width: 90%;
  /* margin-top: 2%; */
}


/* Boton de confirmar y precio total */
.confirmAndTotal {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 90%;
  border-radius: 50px;
  height: 10%;
  margin-top: 7%;
  /* border: 2px solid yellow; */
}

.btnOrder {
  width: 25%;
  height: 60%;
  font-size: 16px;
  font-weight: bold;
  background-color: #FF5C00;
  border: none;
  color: #ffffff;
  border-radius: 50px;
  cursor: pointer;
}

.totalPriceCart {
  display: flex;
  justify-content: left;
  padding-left: 3%;
  align-items: center;
  width: 35%;
  height: 60%;
  background-color: #FFF5EE;
  border-radius: 50px;
}

.totalPriceCart p {
  margin: 2%;
  font-weight: bold;
  font-size: 16px;
}

.priceTotal {
  color: #FF5C00;
}


.containerCardsMenu {
  display: flex;
  justify-content: center;
  position: relative;
  align-items: flex-start;
  width: 85%;
  height: 85%;
  margin-top: 3%;
  padding-top: 1%;
  margin-bottom: 3%;
  /* border: 3px solid red; */
}


/* Cambios hechos - union de componentes: userProducts & Cart */
.containerCartAndMenu {
  display: flex;
  width: 60vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.containerLinksUsers {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1%;
  margin-right: 1%;
  text-align: center;
  background-color: #ffffff;
  border: 1px solid #FF5C00;
  padding: 0.5rem 2rem;
  border-radius: 25px;
  cursor: pointer;
}

.activeLinkUsers {
  text-decoration: none;
  color: #ffffff;
  font-size: 18px;
}

.activeUsers {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1%;
  margin-right: 1%;
  background-color: #FF5C00;
  border: 1px solid #FF5C00;
  padding: 0.5rem 2rem;
  border-radius: 25px;
}

.categoriesUsers {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #FF5C00;
  font-size: 18px;
}

.containerOrdersUsers {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  flex-wrap: wrap;
  width: 85%;
  height: 100%;
  /* border: 3px solid rgb(0, 0, 0); */
  border-radius: 25px;
  margin-top: 5%;
  margin-bottom: 5%;
}

/* ocultar barra de scroll */
.containerOrdersUsers::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}

.containerImgOrderUsers {
  position: absolute;
  bottom: 12rem;
  width: 60%;
  height: 50%;
  left: 3rem;
  /* border: 1px solid red; */
  border-radius: 100%;
}

/* notificaciones:  */
.containerNotifications {
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: space-evenly;
}

.contentCardNotify {
  background-color: white;
  border-radius: 1rem;
  /* box-shadow: 0px 0px 6px rgba(251, 249, 249, 0.2); */
  overflow: hidden;
  width: 400px;
  box-shadow: 0px 0px 9px #FF5C00;
  cursor: pointer;
}

.contentCardNotify:hover {
  border: 1px solid #FF5C00;
  transform: scale(1);

}

.btnNotifyCancel:hover,
.btnNotifyShow:hover,
.btnNotifyGet:hover,
.btnNotifyTake:hover {
  transform: scale(1.1);
}


.titleNotify {
  color: #212121;
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}

.titleNotify::after {
  color: red;
}

.textNotify {
  color: #FF5C00;
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}

.containerBtnNotify {
  display: flex;
  justify-content: space-between;
  padding: 16px;
}

.btnMesero {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
}

.btnNotifyShow {
  display: inline-block;
  background-color: #38ec3e;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: bolder;
  margin-right: 8px;
}

.btnNotifyTake {
  display: inline-block;
  background-color: #FF5C00;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: bolder;
  margin-right: 8px;
}

.btnNotifyGet {
  display: inline-block;
  font-weight: bolder;
  background-color: #FF5C00;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin-right: 8px;
}

.btnNotifyCancel {
  font-weight: bolder;
  background-color: #f62212;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.contentTitleNotify {
  padding: 16px;
}

.btnDeleteUser{
  
  width: 2rem;
  height: 2rem;
  color: white;
  background-color: red;
  border-style: none;
  border-radius: 30%;
}

.containerUsersBtn{
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}


@media only screen and (min-width: 1024px) and (max-width: 1366px) {

  /* contenedor de orden-carrito */
  .containerProductCart {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    background-color: #f5f3f3;
    border-radius: 50px;
    width: 95%;
    height: 40%;
  }

  .infoProduct {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    width: 25%;
    height: 100%;
    /* border: 2px solid blue; */
  }

  .priceProduct {
    font-size: 13.5px;
    width: 100%;
    margin-right: 50%;
    /* border: 2px solid red; */
  }

  /* contenedor de botones sumar y restar cantidad */
  .containerSubAndAdd {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 50%;
  }

  /* botones */
  .btnSubQuantity {
    cursor: pointer;
    width: 20%;
    height: 55%;
    border: none;
    border-radius: 25px;
    background-color: #FF5C00;
    color: #ffffff;
    font-size: 15px;
  }

  .btnAddQuantity {
    cursor: pointer;
    width: 20%;
    height: 55%;
    border: none;
    border-radius: 25px;
    background-color: #FF5C00;
    color: #ffffff;
    font-size: 15px;
  }

  .btnDeleteCart {
    width: 50%;
    height: 30%;
    background-color: transparent;
    border-radius: 12px;
    cursor: pointer;
    border: none;
    cursor: pointer;
    margin-left: 25%;
  }

  .productName {
    margin-right: 1rem;
    font-size: 15px;
  }
  .productPay{
    display: flex;
    font-weight: bold;
    color: #FF5C00;
    font-size: 15px;
    margin-right:3rem;
  }

  .titleShoppingCart {
    font-size: 40px;
    font-family: 'Roboto Slab', serif;
    margin-bottom: 2%;
  }

  .infoCart {
    display: flex;
    cursor: pointer;
    justify-content: center;
    text-align: center;
    align-items: center;
    border: 1px solid #FF5C00;
    width: 70%;
    height: 13%;
    background-color: #fde8d9;
    margin-top: 3%;
    border-radius: 50px;
    gap: 25px;
  }


  .confirmAndTotal {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 90%;
    border-radius: 50px;
    height: 10%;
    margin-top: 4%;
    /* border: 2px solid yellow; */
  }

  .btnOrder {
    width: 25%;
    height: 90%;
    font-size: 14px;
    font-weight: bold;
    background-color: #FF5C00;
    border: none;
    color: #ffffff;
    border-radius: 50px;
    cursor: pointer;
  }

  .totalPriceCart {
    display: flex;
    justify-content: left;
    padding-left: 3%;
    align-items: center;
    width: 35%;
    height: 90%;
    background-color: #FFF5EE;
    border-radius: 50px;
    font-size: 10px;
  }

  .totalPriceCart p {
    margin: 1.5%;
    font-weight: bold;
    font-size: 13.5px;
  }

  .containerImageProduct {
    width: 20%;
    height: 100%;
    border-radius: 50px;
    box-shadow: 8px 0px 12px rgba(0, 0, 0, 0.2);
  }
}



/* Estilos para teléfonos móviles */
@media only screen and (max-width: 600px) {

  .mainProductsUser {
    display: flex;
    justify-content: center;
    width: 100vw;
    align-items: center;
    height: 100vh;
    justify-content: center;
    background: #e1dac6;
  }

  .containerCategories {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 8%;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 0.5rem;
    margin-left: 0;
    margin-right: 0;
    /* border: 1px solid green; */
    box-sizing: border-box;
  }

  .containerLinks {
    padding: 0.5rem 0.5rem;
    margin-right: 0.5em;
    /* Añadido un margen entre los elementos para separarlos visualmente */
  }

  .containerLinks:last-child {
    margin-right: 0;
    /* Eliminado el margen derecho del último elemento para evitar un espacio en blanco */
  }


  .containerLinks {
    display: flex;
    padding: 0.5rem 0.5rem;
    margin-right: 0.5em;
    /* Añadido un margen entre los elementos para separarlos visualmente */
    margin-left: 0;
    background-color: #ffffff;
    border: 1px solid #FF5C00;
    padding: 0.6rem 1.5rem;
    border-radius: 25px;
  }

  .active {
    margin-left: 0;
    margin-right: 0.5em;
    background-color: #FF5C00;
    border: 1px solid #FF5C00;
    padding: 0.6rem 1.5rem;
    border-radius: 25px;
  }

  .activeLink {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #ffffff;
    font-size: 15px;
  }

  .categories {
    font-size: 15px;
  }

  .containerCardsMenu {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100vw;
    height: 70%;
    overflow-y: auto;
    margin-top: 4%;
    padding-top: 3.5em;
    /* border: 1px solid rgb(0, 0, 0); */
  }

  /* ocultar barra de scroll */
  .containerCardsMenu::-webkit-scrollbar {
    background-color: transparent;
  }

  .cardOrderUser {
    display: flex;
    cursor: pointer;
    justify-content: center;
    flex-direction: column;
    margin-top: 1em;
    margin-left: 1.2%;
    margin-right: 1.2%;
    position: relative;
    background-color: #FFFFFF;
    border-radius: 25px;
    width: 12.3em;
    height: 13.2em;
    margin-bottom: 3.3em;
  }

  .cardOrderUser:hover {
    border: 1px solid #FF5C00;
    color: black;
  }

  /* contenedor nombre y categoria */
  .infoOrderUsers {
    margin-top: 40%;
  }

  .containerImgOrderUser {
    position: absolute;
    bottom: 9.2rem;
    width: 65%;
    height: 57%;
    left: 2rem;
    border-radius: 100%;
  }

  .nameOrderUser {
    text-align: left;
    overflow-wrap: anywhere;
    font-size: 1rem;
    margin-left: 1.2rem;
    margin-top: -0.4rem;
  }

  .categoryOrderUser {
    text-align: left;
    overflow-wrap: anywhere;
    font-size: 1rem;
    margin-left: 1.2rem;
    margin-top: -0.4rem;
  }

  .nameOrderUserLabel {
    text-align: left;
    overflow-wrap: anywhere;
    font-size: 1rem;
    font-weight: bold;
    margin-right: 5.5rem;
    margin-top: -0.4rem;
    color: #FF5C00;
  }

  .categoryOrderUserLabel {
    text-align: left;
    overflow-wrap: anywhere;
    font-size: 1rem;
    font-weight: bold;
    margin-right: 4.5rem;
    margin-top: -0.4rem;
    color: #FF5C00;
  }

  .containerButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 35%;
    margin-top: 2%;
    padding-bottom: 3%;
    text-align: center;
    /* border: 2px solid green; */
  }

  .btnAddToCar {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 1%;
    height: 1%;
    padding: 0.8rem;
    margin-right: 1.5rem;
    border: none;
    background-color: #FF5C00;
    border-radius: 25px;
    font-size: 15px;
    font-weight: 100;
    color: white;
    cursor: pointer;
  }


  .containerTittle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 4.5em;
    margin-top: 0;
    /* border: 2px solid black; */
  }

  .containerIconToCart {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4em;
    height: 3.4em;
    background-color: #ffffff;
    border-radius: 7px;
    cursor: pointer;
    margin-left: auto;
    margin-right: 1.5em;
  }

  .iconToCar {
    width: 38%;
    height: 40%;
  }


  .tittleMenuUser {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FF5C00;
    padding: 0.4rem 2.2em;
    border-radius: 30px;
    margin-right: -5.5em;
    margin-left: auto;
  }

  .tittleUser {
    color: white;
    font-size: 30px;
    font-family: 'Instrument Serif', serif;
    font-family: 'Roboto Slab', serif;
    font-weight: 200;
  }

  /* ocultar barra de scroll */
  .containerCategories::-webkit-scrollbar {
    background-color: transparent;
  }

  /* ...... */

  /* Modal de Carrito */

  .mainShoppingCart {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
  }



  .closeModal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    padding: 0.8rem;
    left: 90%;
    top: 1.8%;
    width: 2%;
    height: 2%;
    border-radius: 50px;
    border: none;
    color: white;
    background: #ff0000;
    font-size: 1.2em;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
  }

  .contentShoppingCart {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background: #e1dac6;
    border-radius: 0px;
    border: none
  }

  .titleShoppingCart {
    font-size: 30px;
    font-family: 'Roboto Slab', serif;
    margin-bottom: 2%;
  }

  /* Contenedor - seleccionar forma de pago */
  .infoCart {
    display: flex;
    cursor: pointer;
    justify-content: center;
    text-align: center;
    align-items: center;
    border: 1px solid #FF5C00;
    width: 95%;
    height: 11%;
    background-color: #ffffff;
    margin-top: 5%;
    border-radius: 50px;
    gap: 25px;
  }

  .containerLine {
    height: 2%;
    width: 100vw;
  }

  .line {
    width: 100vw;
    margin-top: 1%;
    border: 1px solid black;
  }

  /* Contenedor del titulo del carrito */
  .containerTittleCart {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 10%;
    width: 100vw;
    margin-bottom: 3%;
  }

  /* Boton de confirmar y precio total */
  .confirmAndTotal {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 12px;
    width: 100%;
    border-radius: 0px;
    height: 15%;
    margin-top: 7%;
    /* border: 2px solid rgb(25, 25, 24); */
  }


  .btnOrder {
    width: 100%;
    height: 45%;
    font-size: 14px;
    font-weight: bold;
    background-color: #FF5C00;
    border: none;
    color: #ffffff;
    border-radius: 50px;
    cursor: pointer;
  }

  .totalPriceCart {
    display: flex;
    justify-content: space-between;
    padding-right: 3%;
    align-items: center;
    width: 100%;
    height: 45%;
    background-color: #FFF5EE;
    border-radius: 50px;
  }

  .totalPriceCart p {
    margin: 2%;
    font-weight: bold;
    font-size: 16px;
  }

  /* Contenedor de imgBank - pay */
  .containerImgPay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22%;
    height: 100%;
    /* border: 1px solid green; */
  }

  .customSelect {
    text-align: center;
    width: 100%;
    height: 45%;
    font-size: 14.5px;
    border: 1px solid #00000000;
    border-radius: 4px;
    background-color: transparent;
    color: #000000;
    outline: none;
  }

  .cart-emptyCart {
    font-size: 14px;
  }

  .containerProductCart {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 3.5%;
    margin-right: 3.5%;
    background-color: #f5f3f3;
    border-radius: 50px;
    width: 100vw;
    height: 22%;
    border: 1px solid #FF6900;
  }

  .containerProductsShoppingCart {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow-y: auto;
    width: 100vw;
    height: 50%;
    padding-top: 2%;
    padding-bottom: 5%;
    margin: 2%;
    gap: 18px;
    /* border: 3px solid rgb(0, 0, 0); */
  }

  .infoProduct {
    display: flex;
    text-align: left;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: column;
    width: 48%;
    height: 90%;
    margin-left: -5%;
    /* border: 1px solid red; */
  }

  .productName {
    font-size: 13.5px;
  }

  .containerImageProduct {
    width: 21.5%;
    height: 100%;
    border-radius: 50px;
    box-shadow: 8px 0px 12px rgba(0, 0, 0, 0.2);
  }

  .containerSubAndAdd {
    display: flex;
    justify-content: left;
    gap: 8px;
    align-items: center;
    width: 50%;
    height: 35%;
    /* border: 1px solid #FF5C00; */
    margin: 0%;
    border-radius: 50px;
  }

  /* botones */
  .btnSubQuantity {
    cursor: pointer;
    width: 25%;
    height: 95%;
    border: none;
    border-radius: 25px;
    background-color: #FF5C00;
    color: #ffffff;
    font-size: 13px;
    font-weight: bold;
  }

  .btnAddQuantity {
    cursor: pointer;
    width: 25%;
    height: 95%;
    border: none;
    border-radius: 25px;
    background-color: #FF5C00;
    color: #ffffff;
    font-size: 13px;
    font-weight: bold;
  }

  .quantityProduct {
    font-size: 12px;
  }

  .containerButtonCart {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 50px;
    width: 10%;
    height: 100%;
    margin-right: 7%;
    /* border: 1px solid blue; */
  }

  .priceProduct {
    font-size: 14px;
    width: 100%;
    margin-right: 50%;
    /* border: 2px solid red; */
  }

  .deleteToCar {
    width: 100%;
    height: 100%;
    margin-left: 20%;
    /* background-color: #38ec3e; */
  }

  .cart-empty {
    width: 50%;
  }
}


/* Estilos para teléfonos móviles de 320px */
@media only screen and (max-width: 320px) {

  /* Contenedor - seleccionar forma de pago */
  .infoCart {
    display: flex;
    cursor: pointer;
    justify-content: center;
    text-align: center;
    align-items: center;
    border: 1px solid #FF5C00;
    width: 95%;
    height: 8%;
    background-color: #ffffff;
    margin-top: 5%;
    border-radius: 50px;
    gap: 25px;
  }


  .containerProductCart {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 3.5%;
    margin-right: 3.5%;
    background-color: #f5f3f3;
    border-radius: 50px;
    width: 100vw;
    height: 18%;
    border: 1px solid #FF6900;
  }


  .containerIconToCart {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.5em;
    height: 3.2em;
    background-color: #ffffff;
    border-radius: 7px;
    cursor: pointer;
    margin-left: auto;
    margin-right: 1em;
  }

  .tittleMenuUser {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.2rem 2.2em;
    border-radius: 30px;
    margin-right: -3em;
    margin-left: auto;
  }
}



/* Tablet */
@media only screen and (max-width:880px) and (min-width:600px) {

  .mainProductsUser {
    display: flex;
    justify-content: center;
    width: 100vw;
    align-items: center;
    height: 100vh;
    justify-content: center;
    background: #e1dac6;
  }

  .containerCategories {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10%;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 0.5rem;
    margin-left: 0;
    margin-right: 0;
    /* border: 1px solid green; */
    box-sizing: border-box;
  }


  .containerLinks {
    display: flex;
    padding: 1rem 1rem;
    margin-right: 0.5em;
    margin-left: 0;
    background-color: #ffffff;
    border: 1px solid #FF5C00;
    padding: 0.8rem 2.5rem;
    border-radius: 25px;
  }


  .containerLinks:last-child {
    margin-right: 0;
    /* Eliminado el margen derecho del último elemento para evitar un espacio en blanco */
  }

  .active {
    margin-left: 0;
    margin-right: 0.5em;
    background-color: #FF5C00;
    border: 1px solid #FF5C00;
    padding: 0.8rem 2.5rem;
    border-radius: 25px;
  }

  .activeLink {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #ffffff;
    font-size: 20px;
  }

  .categories {
    font-size: 20px;
  }

  .containerCardsMenu {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100vw;
    height: 70%;
    overflow-y: auto;
    margin-top: 2.5%;
    padding-top: 3.5em;
    /* border: 1px solid rgb(0, 0, 0); */
  }

  /* ocultar barra de scroll */
  .containerCardsMenu::-webkit-scrollbar {
    background-color: transparent;
  }

  .cardOrderUser {
    display: flex;
    cursor: pointer;
    justify-content: center;
    flex-direction: column;
    margin-top: 3.2em;
    margin-left: 1%;
    margin-right: 1%;
    position: relative;
    background-color: #FFFFFF;
    border-radius: 25px;
    width: 18em;
    height: 17em;
    margin-bottom: 5em;
  }

  .cardOrderUser:hover {
    border: 1px solid #FF5C00;
    color: black;
  }

  /* contenedor nombre y categoria */
  .infoOrderUsers {
    margin-top: 25%;
  }

  .containerImgOrderUser {
    position: absolute;
    bottom: 12.5rem;
    width: 60%;
    height: 60%;
    left: 3.5rem;
    border-radius: 100%;
  }

  .nameOrderUser {
    text-align: left;
    overflow-wrap: anywhere;
    font-size: 1.3rem;
    margin-left: 1.7rem;
    margin-top: -0.4rem;
  }

  .categoryOrderUser {
    text-align: left;
    overflow-wrap: anywhere;
    font-size: 1.3rem;
    margin-left: 1.7rem;
    margin-top: -0.4rem;
  }

  .nameOrderUserLabel {
    text-align: left;
    overflow-wrap: anywhere;
    font-size: 1.3rem;
    font-weight: bold;
    margin-right: 9.2rem;
    margin-top: -0.4rem;
    color: #FF5C00;
  }

  .categoryOrderUserLabel {
    text-align: left;
    overflow-wrap: anywhere;
    font-size: 1.3rem;
    font-weight: bold;
    margin-right: 8rem;
    margin-top: -0.4rem;
    color: #FF5C00;
  }

  .containerButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 35%;
    margin-top: 2%;
    padding-bottom: 3%;
    text-align: center;
    font-size: 1.3rem;
    /* border: 2px solid green; */
  }


  .btnAddToCar {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 1%;
    height: 1%;
    padding: 1rem;
    margin-right: 1.5rem;
    border: none;
    background-color: #FF5C00;
    border-radius: 25px;
    font-size: 15px;
    font-weight: 100;
    color: white;
    cursor: pointer;
  }


  .containerTittle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 4.5em;
    margin-top: 0;
    /* border: 2px solid black; */
  }

  .containerIconToCart {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4.6em;
    height: 4em;
    background-color: #ffffff;
    border-radius: 7px;
    cursor: pointer;
    margin-left: auto;
    margin-right: 1.5em;
  }

  .iconToCar {
    width: 40%;
    height: 45%;
  }

  .tittleMenuUser {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FF5C00;
    padding: 0.6rem 5em;
    border-radius: 30px;
    margin-right: -5.5em;
    margin-left: auto;
  }

  .tittleUser {
    color: white;
    font-size: 32px;
    font-family: 'Instrument Serif', serif;
    font-family: 'Roboto Slab', serif;
    font-weight: 200;
  }

  /* ocultar barra de scroll */
  .containerCategories::-webkit-scrollbar {
    background-color: transparent;
  }

  /* ...... */

  /* Modal de Carrito */

  .mainShoppingCart {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
  }

  .closeModal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    padding: 1.5rem;
    left: 91%;
    top: 2.5%;
    width: 2%;
    height: 2%;
    border-radius: 50px;
    border: none;
    color: white;
    background: #ff0000;
    font-size: 1.7em;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
  }

  .contentShoppingCart {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background: #e1dac6;
    border-radius: 0px;
    border: none
  }

  .titleShoppingCart {
    font-size: 45px;
    font-family: 'Roboto Slab', serif;
    margin-bottom: 2%;
  }

  /* Contenedor - seleccionar forma de pago */
  .infoCart {
    display: flex;
    cursor: pointer;
    justify-content: center;
    text-align: center;
    align-items: center;
    border: 1px solid #FF5C00;
    width: 95%;
    height: 12%;
    background-color: #ffffff;
    margin-top: 5%;
    border-radius: 50px;
    gap: 25px;
  }

  .containerLine {
    height: 2%;
    width: 100vw;
  }

  .line {
    width: 100vw;
    margin-top: 1%;
    border: 1px solid black;
  }

  /* Contenedor del titulo del carrito */
  .containerTittleCart {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 10%;
    width: 100vw;
    margin-bottom: 3%;
  }

  /* Boton de confirmar y precio total */
  .confirmAndTotal {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 12px;
    width: 100%;
    border-radius: 0px;
    height: 20%;
    margin-top: 4%;
    /* border: 2px solid rgb(25, 25, 24); */
  }


  .btnOrder {
    width: 100%;
    height: 45%;
    font-size: 23px;
    font-weight: bold;
    background-color: #FF5C00;
    border: none;
    color: #ffffff;
    border-radius: 50px;
    cursor: pointer;
  }

  .totalPriceCart {
    display: flex;
    justify-content: space-between;
    padding-right: 3%;
    align-items: center;
    width: 100%;
    height: 45%;
    background-color: #FFF5EE;
    border-radius: 50px;
  }

  .totalPriceCart p {
    margin: 2%;
    font-weight: bold;
    font-size: 23px;
  }

  /* Contenedor de imgBank - pay */
  .containerImgPay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18%;
    height: 90%;
    /* border: 1px solid green; */
  }

  .customSelect {
    text-align: center;
    width: 90%;
    height: 45%;
    font-size: 19px;
    border: 1px solid #00000000;
    border-radius: 4px;
    color: #000000;
    outline: none;
  }

  .cart-emptyCart {
    font-size: 25px;
  }

  .containerProductCart {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 3.5%;
    margin-right: 3.5%;
    background-color: #f5f3f3;
    border-radius: 50px;
    width: 100vw;
    height: 30%;
    border: 1px solid #FF6900;
  }

  .containerProductsShoppingCart {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow-y: auto;
    width: 100vw;
    height: 50%;
    padding-top: 1%;
    padding-bottom: 5%;
    margin: 2%;
    gap: 18px;
    /* border: 3px solid rgb(0, 0, 0); */
  }

  .infoProduct {
    display: flex;
    text-align: left;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: column;
    width: 48%;
    height: 90%;
    margin-left: -12%;
    /* border: 1px solid red; */
  }

  .productName {
    font-size: 1.2em;
  }

  .containerImageProduct {
    margin-left: -0.5%;
    width: 17.5%;
    height: 100%;
    border-radius: 50px;
    box-shadow: 8px 0px 12px rgba(0, 0, 0, 0.2);
  }

  .containerSubAndAdd {
    display: flex;
    justify-content: left;
    gap: 8px;
    align-items: center;
    width: 35%;
    height: 32%;
    /* border: 1px solid #FF5C00; */
    margin: 0%;
    border-radius: 50px;
  }

  /* botones */
  .btnSubQuantity {
    cursor: pointer;
    width: 25%;
    height: 100%;
    border: none;
    border-radius: 25px;
    background-color: #FF5C00;
    color: #ffffff;
    font-size: 22px;
    font-weight: bold;
  }

  .btnAddQuantity {
    cursor: pointer;
    width: 25%;
    height: 100%;
    border: none;
    border-radius: 25px;
    background-color: #FF5C00;
    color: #ffffff;
    font-size: 22px;
    font-weight: bold;
  }

  .quantityProduct {
    font-size: 22px;
  }

  .containerButtonCart {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 50px;
    width: 10%;
    height: 100%;
    margin-right: 5%;
    /* border: 1px solid blue; */
  }

  .priceProduct {
    font-size: 20px;
    width: 100%;
    margin-right: 5%;
    /* border: 2px solid red; */
  }

  .deleteToCar {
    width: 95%;
    height: 100%;
    margin-left: 50%;
    /* background-color: #38ec3e; */
  }

  .cart-empty {
    width: 50%;
    font-size: 1.3rem;
  }
}